<template>
  <v-app style=" background: #1E88E5;">
    <v-app-bar
      app
      color="primary"
      dark
      flat
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo.svg')"
          transition="scale-transition"
          width="120"
        />

      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="/"
        text
      >
        <span class="mr-2">Начало</span>
      </v-btn>

    </v-app-bar>
    <v-main>
      <v-container
        fill-height
        fluid
      >
        <router-view />

      </v-container>
      <v-footer color="primary">
        <v-col
          class="text-center"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>Sitemix</strong>
        </v-col>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {},
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style lang="sass" >
</style>
