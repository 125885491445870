<template>
  <v-row
    justify="center"
    align="center"
  >
    <v-col
      align="center"
      cols="12"
      sm="12"
      md="6"
    >
      <v-container class="mx-auto ma-0 pa-0 fill-height">
        <h1
          class="display-3"
          style="color:#E1F5FE"
        >
          Добре дошли в Sitemix
        </h1>
        <p class="ma-6 white--text font-weight-bold headline ">
          <v-icon
            large
            color="green"
          >
            mdi-check-bold
          </v-icon>
          Бизнес или личен сайт на конкурентни цени
        </p>
        <p class="ma-6 white--text font-weight-bold headline">
          <v-icon
            large
            color="green"
          >
            mdi-check-bold
          </v-icon>
          Изграждане и подръжка на Вашият сайт, <br>онлайн магазин или блог
        </p>
      </v-container>
    </v-col>
    <v-col
      align="center"
      cols="12"
      sm="12"
      md="6"
    >
      <v-row>
        <v-col
          align="center"
          cols="12"
          sm="6"
          md="6"
        >
          <v-card
            class="mx-auto"
            outlined
            color="#F9FBE7"
          >
            <v-card-title class="text-h5">
              Твоят сайт
            </v-card-title>
            <v-card-subtitle>Ние ще изградим твоят сайт с модерни технологии .</v-card-subtitle>
            <v-card-actions>
              <v-btn text>
                <v-icon>mdi-play</v-icon> Listen Now
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card
            class="mx-auto mt-5"
            outlined
            color="#18FFFF"
          >
            <v-card-title class="text-h5">
              Unlimited music now
            </v-card-title>
            <v-card-subtitle>Listen to your favorite artists and albums whenever and wherever, online and offline.</v-card-subtitle>
            <v-card-actions>
              <v-btn text>
                <v-icon>mdi-play</v-icon> Listen Now
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col
          align="center"
          cols="12"
          sm="6"
          md="6"
        >

          <v-card
            class="mx-auto "
            outlined
            color="#385F73"
          >
            <v-card-title class="text-h5">
              Unlimited music now
            </v-card-title>
            <v-card-subtitle>Listen to your favorite artists and albums whenever and wherever, online and offline.</v-card-subtitle>
            <v-card-actions>
              <v-btn text>
                <v-icon>mdi-play</v-icon> Listen Now
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card
            class="mx-auto mt-5"
            outlined
            color="#385F73"
          >
            <v-card-title class="text-h5">
              Unlimited music now
            </v-card-title>
            <v-card-subtitle>Listen to your favorite artists and albums whenever and wherever, online and offline.</v-card-subtitle>
            <v-card-actions>
              <v-btn text>
                <v-icon>mdi-play</v-icon> Listen Now
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-col>

      </v-row>
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: "Home",

  components: {},
  data() {
    return {};
  },
};
</script>
