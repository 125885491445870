<template>
  <v-row
    justify="center"
    align="center"
  >
    <v-col
      align="center"
      cols="12"
      sm="6"
      md="6"
    >

      <h1
        class="display-3"
        style="color:#E1F5FE"
      >
        Добре дошли в Sitemix
      </h1>
      <p class="ma-6 white--text font-weight-bold headline ">
        <v-icon
          large
          color="green"
        >
          mdi-check-bold
        </v-icon>
        Ние започнахме работа ,но все още изграждаме нашият сайт.
        Междувременно може да ни потърсите на
        s&#117;&#112;port&#64;site&#109;ix&#46;net

      </p>

    </v-col>
  </v-row>

</template>

<script>
export default {
  name: "underBuild",

  components: {},
  data() {
    return {};
  },
};
</script>
